import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "reading-tracker-awards"
    }}>{`Reading Tracker Awards`}</h1>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Patron's can review their reading awards by selecting 'Awards' while in the Reading Tracker Homescreen. Unlocked reading rewards will be displayed in this section. `}</li>
    </ol>
    <p><img alt="Reading Tracker Awards" src={require("./images/reading_tracker_awards.png")} />{` `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      